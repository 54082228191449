import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useSaasCardStyles = makeStyles<Theme>(
  ({ palette: { primary, divider }, shadows, spacing }) => ({
    saasCard: {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: shadows[6],
      },
    },
    saasCardLogo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: spacing(),
      paddingBottom: spacing(),
      borderBottom: `1px solid ${divider}`,
    },
    saasCardContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    saasTitle: {
      fontSize: '1.25rem',
      textAlign: 'center',
      paddingBottom: spacing(2),
      '& a': {
        color: primary.main,
        textDecoration: 'none',
      },
    },
  })
);
