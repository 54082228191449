import {gql} from 'graphql-request';

// Gets both the registered and discovered domains in a single query
export const companyDomainsQuery = gql`
query GetDomains {
    client
    {
        registered_domains(order_by:{name: asc})
        {
            pub_id
            name
        }
        discovered_domains(order_by:{name :asc})
        {
            pub_id
            name
        }
    }
}`;

// Mutation for deleting a domain
export const deleteCompanyDomainMutation = gql`
mutation DeleteDomain($req: DeleteDomainRequest!) {
  delete_company_domain(req: $req) {
    id
  }
}`;

// Mutation for adding a new domain
export const addCompanyDomainMutation = gql`
mutation AddDomain($req:CreateDomainRequest!){
  insert_company_domain(req:$req) {
    id
  }
}`;
