import {Box, Menu, MenuItem} from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import SourceIcon from '@mui/icons-material/Source';
import StartIcon from '@mui/icons-material/Start';
import React from "react";
import {ISaas} from "src/types/Saas";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {
  ellipsisMenuProps,
  EllipsisRender
} from "src/components/common/Tables/Utilities/Ellipsis";
import {useNavigate} from "react-router-dom";
import {combineSaasNicknameWithName} from "src/utils/saasUtils";
import {finishConnectionSetupRoute} from "../../../AdminNavigation";
import {saasCanFinishConnectionSetup} from "../../../AddAndUpdateSaas/dialogs/misc/ConnectionStructures";

const menuItemStyling = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '13px',
}

interface theMenuProps extends ellipsisMenuProps {
  saas: ISaas,
  connectionId: string,
  nickname?: string
  setSelectedSaas: Function,
  setSelectedConnectionId: Function,
  // is this really needed?
  setSelectedNickname: Function

  setIsConfirmationDialogOpen: Function,
  handleUpdateConnection: Function,
}

export const ConnectedSaasActionMenu = ({
  // General menu control
  anchorEl,
  open,
  doOpenMenu,
  handleClose: handleCloseActionMenu,

  // specific menu props
  saas,
  connectionId,
  nickname,
  setSelectedSaas,
  setSelectedConnectionId,
  setSelectedNickname,
  setIsConfirmationDialogOpen,
  handleUpdateConnection,
}: theMenuProps) => {
  // console.log({saas}, {connectionId});

  const navigate = useNavigate();

  const handleRemoveClick = () => {
    // console.log({saas}, {connectionId});
    setSelectedSaas(saas);
    setSelectedConnectionId(connectionId);
    // why do we do this?  needed?
    setSelectedNickname(nickname);

    handleCloseActionMenu();
    setIsConfirmationDialogOpen(true);
  }

  const handleUpdateConnectionClick = () => {
    // console.log({saas}, {connectionId});
    setSelectedSaas(saas);
    setSelectedConnectionId(connectionId);
    handleCloseActionMenu();
    setTimeout(handleUpdateConnection(),0)
  }

  const iconSize = '1.3rem';

  // we need a more up-to-date nickname than the one
  // from saas.calculatedNickname
  // const saasDisplayName = isEmptyString(nickname) ?
  //   saas.calculatedName :
  //   nickname;
  const saasDisplayName = combineSaasNicknameWithName(
    saas.name,
    nickname
  )
  return (<>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseActionMenu}
    >
      <MenuItem onClick={handleUpdateConnectionClick}>
        <Box sx={menuItemStyling}>
          <AppRegistrationIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Update ${saasDisplayName}`}
          </div>
        </Box>
      </MenuItem>
      <MenuItem onClick={handleRemoveClick}>
        <Box sx={menuItemStyling}>
          <RemoveCircleOutlineIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Remove ${saasDisplayName}`}
          </div>
        </Box>
      </MenuItem>
      {
          saasCanFinishConnectionSetup(saas.identifier) &&
        <MenuItem onClick={() => navigate(`${finishConnectionSetupRoute}/${connectionId}`)}>
          <Box sx={menuItemStyling}>
            <StartIcon sx={{fontSize: iconSize}}/>
            <div>
              {`Finish Setup`}
            </div>
          </Box>
        </MenuItem>
      }
    </Menu>
  </>);
};

// renderCell function for UserGrid
export const renderConnectionEllipsis = ({
  params,
  saas,
  setSelectedSaas,
  setSelectedConnectionId,
  setSelectedNickname,
  setIsOAuth2ConsentDialogOpen,
  setIsConfirmationDialogOpen,
  handleUpdateConnection,
  // setIsErrorDialogOpen,
  // setErrorMessage,
}: {
  params: GridRenderCellParams,
  saas: any,
  setSelectedSaas: any,
  setSelectedConnectionId: any,
  setSelectedNickname: Function,
  setIsOAuth2ConsentDialogOpen: any,
  setIsConfirmationDialogOpen: any,
  handleUpdateConnection: any,
  // setIsErrorDialogOpen: any,
  // setErrorMessage: any,
}) => {
  // console.log({params})
  const connectionId = params.row.connectionId
  const nickname = params.row.nickname

  return (
    <EllipsisRender
      menu={ConnectedSaasActionMenu}
      menuProps={{
        saas: params.row.saas,
        connectionId,
        setSelectedSaas,
        setSelectedConnectionId,
        setSelectedNickname,
        setIsOAuth2ConsentDialogOpen,
        setIsConfirmationDialogOpen,
        handleUpdateConnection,
        nickname,
        // setIsErrorDialogOpen,
        // setErrorMessage,
      }}
    />
  );
};
