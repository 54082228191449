import {FC} from 'react';
import {MainContent} from 'src/components/MainLayout/MainContent';
import {MainLayoutFooter} from 'src/components/MainLayout/MainLayoutFooter';
import {Header} from 'src/components/Header/Header';
import {useClientInfo} from "../../hooks/ClientHooks";
import {PortalContext} from "../../hooks/PortalContext";
import {planTypes} from "../../utils/constants";
import {useUpgradeFeatureDialog} from "../common/UpgradeFeature/useUpgradeFeatureDialog";



export const MainLayout: FC = ({ children }: any) => {
  const { data: clientInfo, loading } = useClientInfo();

  // Set up the feature upgrade dialog
  const {
    dialog: featureUpgradeDialog,
    setIsOpen: setFeatureDialogOpen,
  } = useUpgradeFeatureDialog({
    onCancel: ()=>{setFeatureDialogOpen(false)},
  })


  if (loading || !clientInfo) {
    return <></>
  }

  let portalContext = {
    clientInfo:clientInfo,
    isTrial: clientInfo.planType === planTypes.Trial,
    featureDialog: {
      open: () => setFeatureDialogOpen(true),
      close: () => setFeatureDialogOpen(false),
    }
  }

  return (
    <PortalContext.Provider value={portalContext}>
      {featureUpgradeDialog}
      <Header />
      <MainContent>{children}</MainContent>
      <MainLayoutFooter />
    </PortalContext.Provider>
  );
};
