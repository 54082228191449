import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {compactInfoFontSize} from 'src/theme';

export const useSaasStatusBadgeStyles = makeStyles<Theme>(({ spacing }) => ({
  rootStyles: {
    color: 'white',
    borderRadius: 7,
    minWidth: 100,
    padding: spacing(1),
    textAlign: 'center',
    fontSize: compactInfoFontSize,
    lineHeight: 1,
    textTransform: 'uppercase',
    marginBottom: 2,
  },
  textStyles: {
    lineHeight: 1,
  },
}));
