import {privilegeStrings} from "src/utils/constants";
import {SaasHealthRating} from "src/types/Saas";
import {ISaasUser} from "src/types/SaasUser";
import {
  findUsersWithLevel,
  transformPermissionStatus
} from "../shared/sharedTransforms";
import {pause} from "src/utils/other";
import {calculateHpuRating, calculatePuRating} from "../../utils/StatusCalculator";

// returns this structure:
//   {
//     noOfMfa,
//     name: saas.description,
//     identifier: saasIdentifier,
//     pubId: saas.pub_id,
//     users[],
//     riskPolicyEvents[],
//     externalShares[],
//     externalMailForwards[],
//     highlyPrivilegedUsers[],
//     privilegedUsers[],
//     enabledUsers[],
//     externalUsers[],
//     inactive60DaysUsers[],
//   }

export const privilegeKey = 'privilegeLevel';

export const transformSaasDetails = async (
  queryResponse: {
    saas: {
      description: string;
      name: string;
      pub_id: string;
      latest_saas_users: any[];
      // privilege_levels: any[];
    }[];
    dlp: any [];
    // mail_forward: any[];
    file_share: any[];
  }
): Promise<any> => {
  const shouldLog = false;

  const saas = queryResponse.saas[0];
  const saasIdentifier = saas.name;
  const userRecords = saas.latest_saas_users;
  if(shouldLog)console.log({userRecords});

  // const privilegeLevels = saas.privilege_levels;
  // const noOfMfa = privilegeLevels.reduce(
  //   (accum, level) => accum + level.no_mfa, 0);

  let allUsers: ISaasUser[] = [];
  // let riskPolicyEvents: IRiskPolicyTrigger[] = [];
  // let externalMailForwards: any[] = [];
  // let externalShares: any[] = [];
  let privilegedUsers: any[] = [];
  let highlyPrivilegedUsers: any[] = [];
  let enabledUsers: any[] = [];
  let externalUsers: any[] = [];
  // let inactive60DaysUsers: any[] = [];

  if (shouldLog) console.group('transformQueryResponse');

  const chunkSize = 300;
  const transformUsersChunk = (chunk: any[]) => {
    allUsers = allUsers.concat(chunk.map(transformPermissionStatus))
  }
  for (let i = 0; i < userRecords.length; i += chunkSize) {
    const chunk = userRecords.slice(i, i + chunkSize);
    await pause(100).then(() => transformUsersChunk(chunk));
  }

  if(shouldLog)console.log('transformed', allUsers);

  // const dlpRecords = queryResponse.dlp;
  // await pause(100).then(() =>
  //   riskPolicyEvents = dlpRecords.map(transformDLPEvent)
  // );
  // if(shouldLog)console.log('transformed', riskPolicyEvents);

  // const mailForwards = queryResponse.mail_forward;
  // await pause(100).then(() => {
  //   externalMailForwards = mailForwards.map(transformMailForward);
  //   externalMailForwards = sortArrayByDateProperty(externalMailForwards);
  // });
  // if(shouldLog)console.log('transformed', externalMailForwards);

  // const shares = queryResponse.file_share;
  // await pause(100).then(() =>
  //   externalShares = shares.map(transformExternalShare)
  // );
  // if(shouldLog)console.log('transformed', externalShares);

  if (shouldLog) console.groupEnd();

  // Copy users into useful categories
  // Previously done in SaasStore
  await pause(100).then(() =>
    highlyPrivilegedUsers = allUsers.filter((user) => user[privilegeKey] === privilegeStrings.highlyPrivileged
    )
  );
  await pause(100).then(() =>
    privilegedUsers = allUsers.filter((user) =>
      user[privilegeKey] === privilegeStrings.privileged
    )
  );
  await pause(100).then(() =>
    enabledUsers = allUsers.filter((user) =>
      user[privilegeKey] !== privilegeStrings.highlyPrivileged &&
      user[privilegeKey] !== privilegeStrings.privileged
    )
  );
  await pause(100).then(() =>
    externalUsers = allUsers.filter((user) =>
      user.domain?.toLowerCase() !== 'internal'
    )
  );
  // await pause(100).then(() =>
  //   inactive60DaysUsers = allUsers.filter((user) =>
  //     user.inactive60Days
  //   )
  // );
  // Pack it all up
  const result = {
    saas: {
      name: saas.description,
      identifier: saasIdentifier,
      pubId: saas.pub_id,
    },
    // noOfMfa,
    // these three are just for the summary counts
    users: allUsers,
    highlyPrivilegedUsers,
    privilegedUsers,
    // riskPolicyEvents,
    // externalShares,
    // externalMailForwards,
    enabledUsers,
    allUsers,
    externalUsers,
    // inactive60DaysUsers,
  }
  if(shouldLog) console.log('transformQueryResponse', {result})
  return result;
};

// TODO I think we can now query status
export const calculatedStatus = (
  privilegeLevels: any[],
  saasIdentifier: string
) => {
  let result = SaasHealthRating.GOOD;

  const hpUsers = findUsersWithLevel(
    privilegeLevels,
    privilegeStrings.highlyPrivileged
  );
  const hpuStatus = calculateHpuRating(hpUsers.length, saasIdentifier);

  const pUsers = findUsersWithLevel(
    privilegeLevels,
    privilegeStrings.privileged
  );
  const puStatus = calculatePuRating(pUsers.length);

  if (
    hpuStatus === SaasHealthRating.CRITICAL ||
    puStatus === SaasHealthRating.CRITICAL
  ) {
    result = SaasHealthRating.CRITICAL;
  } else if (
    hpuStatus === SaasHealthRating.MODERATE ||
    puStatus === SaasHealthRating.MODERATE
  ) {
    result = SaasHealthRating.MODERATE;
  }
  return result;
};





  // Graveyard

  // Mock

  // if (false) {// userRecords.length === 0 (caused bug for legitimate zeros) {
  //   users = mockUsers({
  //     saasIdentifier: saasIdentifier,
  //     shouldPickRandomSingleSaas: false
  //   });
  //   riskPolicyEvents = mockRiskPolicyEvents({
  //     saasIdentifier: saasIdentifier,
  //     shouldPickRandomSingleSaas: false
  //   });
  //   externalShares = mockExternalSharing({
  //     saasIdentifier: saasIdentifier,
  //     shouldPickRandomSingleSaas: false
  //   });
  //   externalMailForwards = mockExternalMailForward({
  //     saasIdentifier: saasIdentifier,
  //     shouldPickRandomSingleSaas: false
  //   });
  // }
  // else { // otherwise use query data
