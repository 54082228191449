import memoizee from 'memoizee';
import {wordForChange} from 'src/utils/constants';

export const dashboardRouteFragment = 'dashboard';
export const companyRouteFragment = 'company';
export const privilegeRouteFragment = 'privileged-access';
export const privilegeSummaryRouteFragment = `${privilegeRouteFragment}/summary`

// "exception" routes
export const userAccessRouteFragment = 'user-access';
  export const externalRouteFragment = 'external';
  export const ghostRouteFragment = 'ghost';
  export const inactiveRouteFragment = 'inactive';

export const authenticationRouteFragment = 'auth-exception';
export const exceptionRouteFragment = 'exceptions';
export const driftsRouteFragment = wordForChange;
export const sharingRouteFragment = 'data-sharing';
export const forwardsRouteFragment = 'business-email';

// other
export const saasRouteFragment = 'saas';
export const userRouteFragment = 'user'; // was /user
export const subscriptionRouteFragment = 'subscription';

export const activityLogRouteFragment = 'activity-log';

export const settingsRouteFragment = 'settings';
export const adminRouteFragment = 'admin';

// add saas
export const addSaaas = 'add'
// export const addSaasSuccess = `/${saasRouteFragment}/${addSaaas}/success`;
// export const addSaasError = `/${saasRouteFragment}/${addSaaas}/error`;
export const updateSaas = 'update'

export const addSaasSuccess = `/${addSaaas}/success`;
export const addSaasError = `/${addSaaas}/error`;

export const updateSaasSuccess = `/${updateSaas}/success`;

export const addSaasGuideRouteFragment = `${addSaaas}/guide`;
export const addSaasGuideCmsRouteFragment = '{addSaaas}/guide-cms';
export const addSaasGuideRoute =
  `/${saasRouteFragment}/${addSaasGuideRouteFragment}`
export const addSaasGuideCmsRoute =
  `/${saasRouteFragment}/${addSaasGuideCmsRouteFragment}`

// these urls will be dashboard/<users or apps>
export const usersForAppRouteFragment = 'users-for-app';
// export const appsForUserRouteFragment = 'apps-for-user';

// one-based position of 'company', 'saas', 'dashboard' in route path
// used by NavigationBarMainViewSelect to know where it is
export const positionInPathOfMainAppSection = 1;

//
// used mostly by navigation
//
export const getRoute = (route: string) =>
  `/${route}`;
const gr = getRoute;

export const getSaasRoute = (
  saasIdentifier: string,
  connectionId?: string,
) =>
  `${getRoute(saasRouteFragment)}/${saasIdentifier}${
    connectionId ? `/${connectionId}` : ''
  }`;

// Were used by React Router v5 route managers.
// Now mainly used by navigation
export const dashboardRoute = gr(dashboardRouteFragment);
export const companyRoute = gr(companyRouteFragment);
export const saasRoute = gr(saasRouteFragment);
export const userRouteBase = gr(userRouteFragment); // still used by UserGrid
export const activityLogRoute = gr(activityLogRouteFragment);
export const settingRoute = gr(settingsRouteFragment);
export const adminRoute = gr(adminRouteFragment);

export const userRoute = (userPubId: string) => `${userRouteBase}/${userPubId}`;

// defunct - was used by AppUserColumnBuilder
// export const appsForUserRoute = gr(appsForUserRouteFragment);

export const getCompanyRoute = (fragment: string) =>
  `${companyRoute}/${fragment}`;


// used by CompanyNavBar to identify
// privilege family urls and by privilegedAccessComponent to
// build role routes
export const privilegeRoute = getCompanyRoute(privilegeRouteFragment);

// used by CompanyNavBar and Header/Navigation
export const privilegeSummaryRoute = getCompanyRoute (
  privilegeSummaryRouteFragment
);
export const authenticationRoute = getCompanyRoute (authenticationRouteFragment);
export const forwardsRoute  = getCompanyRoute (forwardsRouteFragment);
export const sharingRoute  = getCompanyRoute (sharingRouteFragment);
export const userAccessRoute  = getCompanyRoute (userAccessRouteFragment);

export const authenticationDriftRoute =
  `${authenticationRoute}/${driftsRouteFragment}`;


//
// This section, for better or worse, addresses query strings
//

// collection of query string keys
export const searchParamKeys = {
  risk: 'risk',
  privilege: 'privilege',
  // used by dashboard
  discovery: 'discovery',
  appId: 'appid',
};

// example url using the above and the below
// `${dashboardRoute}?${searchParamKeys.discovery}=${isShowingApp}&${searchParamKeys.appId}=${app?.pub_id}`
export const isShowingApp = 'app'; // used by dashboard

export const riskTypeIdentifierKeys = {
  newAppsInOrg: "newAppsInOrg",
  newUserApps: "newUserApps",
  zombies: "zombies",
  ghosts: 'ghosts',
  inactive: 'inactive',
  mfaDisabled: 'mfaDisabled',
}

export const riskTypeIdentifiers = {
  privilegedAccess: 'privileged-access',
  allUsers: 'all-users',
  ghosts: 'ghost-users',
  externalUsers: 'external-users',
  dlp: 'dlp',
  externalForwards: 'external-forwards',
  internalForwards: 'internal-forwards',
  externalShares: 'external-shares',
  inactive: 'inactive-users',
  mfaDisabled: 'mfa-disabled-users', // used by Dashboard Page
  newAppsInOrg: 'new-apps-in-org',
  newUserApps: 'new-user-apps',
  zombies: 'zombies',
};

// These functions support the View All feature

// check for a panel specifier in the url and strip any trailing '/'
export const riskTypeToDisplay = (searchParams: any) =>
  searchParams?.get(searchParamKeys.risk)
    ?.replace(/\/$/, '');

// Builds a single-risk-panel url but returns undefined
// if we're showing a single panel.
// Existence of the url informs the TablePanelLabel to
// hide the View All link.
// By default we don't generated a View All link for 5 or fewer records

// export const maxRecordCountToNotReturnViewAllUrl = 5;

export const singlePanelUrlWithObject = memoizee(({
  searchParams,
  riskPanelIdentifier,
  recordCount,
  maxRecordCountToNotReturnViewAllUrl = 5,
} : {
  searchParams: any,
  riskPanelIdentifier: any,
  recordCount: number,
  maxRecordCountToNotReturnViewAllUrl?: number,
}) => {
  let result;
  if (
    !riskTypeToDisplay(searchParams) &&
    recordCount >= (maxRecordCountToNotReturnViewAllUrl || 5) &&
    recordCount > 0
  )
    result = `${window.location.pathname}?${searchParamKeys.risk}=${riskPanelIdentifier}`;
  return result;
});

export const singlePanelUrl = memoizee((
  searchParams: any,
  riskPanelIdentifier: any,
  recordCount: number,
) => {
  return singlePanelUrlWithObject(
    {
    searchParams,
    riskPanelIdentifier,
    recordCount,
  })
});
