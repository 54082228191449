import {
  connectedSaasesQuery,
  getAllSaasQuery,
} from 'src/services/SaasService/SaasUtilityQueries';
import {calculatedStatus} from 'src/services/SaasService/Transforms';
import {transformSaasFlat} from 'src/services/shared/sharedTransforms';
import {ISaasGraphQLResponse} from 'src/types/Saas';
import {combineSaasNicknameWithName} from "src/utils/saasUtils";
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

export const getConnectedSaases = async (
  accessToken: any,
) => {
  const theQuery = async (client: any) => {
    const result = client.request(
      connectedSaasesQuery,
      {
        // temporary filter until we complete switchover
        // from Office 365 to Microsoft 365
        // microsoft365BoolExp: nullCollapsableMicrosoft365()
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken // , signal
    );
    // console.log({response});
  }
  catch (error) {
    console.log('getConnectedSaases failed')
    throw (error)
  }
  const result = response?.client_saas.map((
    {
      saas,
      pub_id,
      nick_name,
    }: {
      saas: ISaasGraphQLResponse; // IClientSaasGroupedGraphQLResponse;
      pub_id: string;
      nick_name: string;
    }
  ) => {
    const calculatedName = combineSaasNicknameWithName(
      saas.description,
      nick_name
    );
    const result = {
      identifier: saas.name,
      name: saas.description,
      calculatedName,
      pubId: saas.pub_id,
      // same as the above but packaged for certain uses
      // TODO return one or the other - probably this one
      // See if anyone uses the one above one "loose" ones
      saas: {
        identifier: saas.name,
        name: saas.description,
        pubId: saas.pub_id,
        calculatedName,
      },
      connectionId: pub_id,
      nickname: nick_name,

      // TODO I think we can now query status
      // also, the saas, above, should have this
      status: calculatedStatus(saas.latest_saas_users, saas.name),
    };
    return result;
  })
  // console.log('getConnectedSaases', {result});
  return result;
}

// All SaaSes
export const getAllSaas = async (accessToken?: any) => {
  const theQuery = async (client: any) => {
    const result = client.request(
      getAllSaasQuery,
      {}
    );
    return result;
  }

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken // , signal
    );
  }
  catch (error) {
    console.error('getAllSaas failed')
    throw (error)
  }

  const result = response.saas.map(transformSaasFlat);
  // console.log('getAllSaas', {result});
  return result;
};
