import {ICompanyAccess} from "../types/Company";
import {createContext, useContext} from "react";

type PortalInfo = {
  clientInfo: ICompanyAccess,
  isTrial: boolean,
  featureDialog?: {
    open: () => void,
    close: () => void,
  }
}
export const PortalContext = createContext<PortalInfo | null>(null);

export const usePortalContext = () => {
  const ctx = useContext(PortalContext);

  if (!ctx) {
    throw new Error(
      "usePortalContext has to be used within <PortalContext.Provider>"
    );
  }

  return ctx;
};