import React from 'react';
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {UserPercentageGraph} from "../../../components/DriftGraph/UserPercentageGraph";
import {DtxSpacer} from "../../../components/common/DtxSpacer";
import {DriftGraphArea} from "../../../components/DriftGraph/DriftGraphArea";
import {
  addDateLabels,
  filterDailyDriftByResolution,
  getDailyCounts,
  IDriftGraphSeries,
  trimData
} from "../../../components/DriftGraph/DriftDataProvider";
import {Box, CircularProgress, MenuItem, Typography, useTheme} from "@mui/material";
import Select from "@mui/material/Select";
import {clickableDivStyles} from "../../../theme";
import {plural} from "../../../utils/string";
import {useNavigate} from "react-router";
import {usePortalContext} from "../../../hooks/PortalContext";

export interface DonutDriftChartProps extends FeatureCountProps {
  title: string;
  count: number;
  outOf: number;
  appCount: number;
  description: React.ReactNode;
  driftSeries: IDriftGraphSeries[];
  isLoading: boolean;
  viewAllUrl?: string;
}


export const DonutDriftChart = (props: DonutDriftChartProps) => {
  const {
    title,
    count,
    outOf,
    appCount,
    description,
    driftSeries,
    isLoading,
    viewAllUrl,
    isFeatureOn,

  } = props;

  const navigate = useNavigate()
  const theme = useTheme();
  const {featureDialog} = usePortalContext();
  const {spacing, palette} = theme;
  const [timePeriod, setTimePeriod] = React.useState('months6');

  const handleNumberClick = () => {
    if (isFeatureOn && viewAllUrl) {
      navigate(viewAllUrl)
    }
    else {
      if (featureDialog?.open) {
        featureDialog?.open();
      }
    }
  }

if (isLoading) {
  return <Box
    sx={{
      minHeight: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }}
  >
    <Typography
      variant="h1"
      sx={{
        color: theme.palette.text.primary,
        fontSize: '1.4rem',
        textAlign: 'left',
        marginBottom: spacing(3),
      }}
    >
      {title}
    </Typography>
    <CircularProgress size={31} /><Typography
    sx={{
      color: theme.palette.text.secondary,
      fontSize: '1rem',
      padding: spacing(1),
    }}
  >Loading... </Typography></Box>
}

  let graphData = getDailyCounts(driftSeries);
  let {scale, trimmedData} = trimData(graphData, timePeriod);
  let driftData = filterDailyDriftByResolution({data: trimmedData, resolution: scale});
  driftData = addDateLabels(driftData);

  return (<>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'normal',
        gap: theme.spacing(1), // vertical gap
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(0.5), // vertical gap
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: '2',
            flexDirection: 'row'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
                width: '100%',
                lineHeight: 'normal'
              }}
            >
        { // If the feature is not enabled, we want to show the link so they can upgrade
          !!viewAllUrl || !isFeatureOn ?
            <Typography
              variant="h1"
              sx={{
                ...clickableDivStyles,
                fontSize: '1.4rem',
                textAlign: 'left'
              }}
              onClick={() => handleNumberClick()}
            >
              {`${count} ${title}`}
            </Typography> :
            <Typography
              variant="h1"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '1.4rem',
                textAlign: 'left'
              }}
            >
              {count} {title}
            </Typography>
        }

        {(appCount > 0) && <Box
          sx={{
            display: 'flex',
            fontStyle: 'italic',
            fontSize: '1.5rem',
            paddingTop: spacing(0.2),
          }}
        >
          <Typography
            sx={{
              color: palette.text.secondary,
              fontSize: '1.1rem',
            }}
          >
            across
          </Typography>
          &nbsp;
          <Typography
            sx={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '1.1rem',
            }}
          >
            {appCount} App{
            plural(appCount)
          }
          </Typography>
        </Box>
        }
          </Box>
            <Box
              sx={{
                color: palette.text.secondary,
              }}
            >{description}</Box>
          </Box>
        </Box>
        <Select data-testid="donut-drift-chart-timespan-select"
                labelId="drift-timespan"
                sx={{height:"40px", width: "150px"}}
                value={timePeriod}
                onChange={(e) => {setTimePeriod(e.target.value)}}>
          <MenuItem key={'days7'} value={'days7'}>7 days</MenuItem>
          <MenuItem key={'days28'} value={'days28'}>28 days</MenuItem>
          <MenuItem key={'months3'} value={'months3'}>3 months</MenuItem>
          <MenuItem key={'months6'} value={'months6'}>6 months</MenuItem>
          <MenuItem key={'months12'} value={'months12'}>12 months</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing(1),
          width: '100%'
        }}
      >
    <UserPercentageGraph count={count} total={outOf} title={title} />

    <DtxSpacer space={10} />
    <div style={{marginTop: '10px', minWidth:'470px', height: '210px', flexGrow:'2', marginLeft:'-40px'}}>
      <DriftGraphArea data={driftData} series={driftSeries} />
    </div>
    </Box>
    </Box>
    </>)
}
