import {FC} from 'react';
import {Route, Routes} from 'react-router';
import {
  MainLayout
} from 'src/components/MainLayout';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {ActivityLogPage} from 'src/pages/ActivityLogPage';
import {
  AppUsersPanel
} from 'src/pages/DashboardPage/Discovery/AppUsersPanel/AppUsersPanel';
import {SettingsPage} from 'src/pages/SettingsPage';
import {AdminPage} from 'src/pages/AdminPage';
import {
  activityLogRouteFragment,
  adminRouteFragment,
  companyRouteFragment,
  privilegeRouteFragment,
  saasRouteFragment,
  settingsRouteFragment,
  userRouteFragment,
  usersForAppRouteFragment,
} from 'src/routes';
import {RouteNotFound} from 'src/RoutesManager/index';
import {RoutesManagerCompany} from 'src/RoutesManager/RoutesManagerCompany';
import {RoutesManagerSaas} from 'src/RoutesManager/RoutesManagerSaas';
import {RoutesManagerUser} from 'src/RoutesManager/RoutesManagerUser';
import {RoutesManagerDashboard} from 'src/RoutesManager/RoutesManagerDashboard';

// Route manager - for /dashboard paths
// Note paths here must be relative to /dashboard/
// e.g. 'company'
export const RoutesManagerMain: FC = () => {
  return (
    <MainLayout>
      <Routes>
        {/* This route prevents infinite redirect after login. */}
        {/* But removing the redirect exposes 403, 404 errors */}
        {/* What does "removing the redirect" mean? */}
        {
          <Route
            path={`/*`}
            element={
              <PrivateRoute component={RoutesManagerDashboard} />
            }
          />
        }
        <Route
          path={`${usersForAppRouteFragment}/:appPubId`}
          element={
            <PrivateRoute
              component={AppUsersPanel}
            />
          }
        />
        <Route
          path={`${privilegeRouteFragment}/*`}
          element={
            <PrivateRoute component={RoutesManagerCompany}/>
          }
        />
        <Route
          path={activityLogRouteFragment}
          element={
            <PrivateRoute
              component={ActivityLogPage}
            />
          }
        />
        <Route
          path={settingsRouteFragment}
          element={
            <PrivateRoute
              component={SettingsPage}
            />
          }
        />
        <Route
          path={`${adminRouteFragment}/*`}
          element={
            <PrivateRoute
              component={AdminPage}
            />
          }
        />
        <Route
          path={`${companyRouteFragment}/*`}
          element={
            <PrivateRoute component={RoutesManagerCompany} />
          }
        />
        <Route
          path={`${saasRouteFragment}/*`}
          element={
            <PrivateRoute component={RoutesManagerSaas} />
          }
        />
        <Route
          path={`${userRouteFragment}/*`}
          element={
            <PrivateRoute component={RoutesManagerUser} />
          }
        />
        <Route
          path="*"
          element={
            <>
              <h3>RoutesManagerMain</h3>
              <RouteNotFound path={window.location.pathname} />
            </>
          }
        />
      </Routes>
    </MainLayout>
  );
};

        // {/*<Route*/}
        // {/*  path={`${appsForUserRouteFragment}/:userPubId`}*/}
        // {/*  element={*/}
        // {/*    <PrivateRoute*/}
        // {/*      component={UserAppsPage}*/}
        // {/*    />*/}
        // {/*  }*/}
        // {/*/>*/}
