import {graphQLClient} from 'src/services/shared/GraphQLService';
import {gql} from "graphql-request";

/*
  asyncCallWithTimeout could be useful if we want a query timeout
  usage:
  const variables = ...
  let result
  try {
    await result = asyncCallWithTimeout(
      client?.request(
        updateSaasNicknameMutation,
        variables
      ), 5000
    );
  }
  catch (error) {...}
  return resul
 */
/**
 * Call an async function with a maximum time limit (in milliseconds) for the timeout
 * @param {Promise<any>} asyncPromise An asynchronous promise to resolve
 * @param {number} timeLimit Time limit to attempt function in milliseconds
 * @returns {Promise<any> | undefined} Resolved promise for async function call, or an error if time limit reached
 */
/*
const asyncCallWithTimeout = async (
  asyncPromise: any,
  timeLimit: number
) => {
  console.log([asyncPromise]);
  let timeoutHandle: any;

  const timeoutPromise = new Promise((_resolve, reject) => {
      timeoutHandle = setTimeout(
          () => {
            console.log('asyncCallWithTimeout - rejecting on timeout');
            reject(new Error('Async call timeout limit reached'))
          },
          timeLimit
      );
  });

  return Promise.race([asyncPromise, timeoutPromise]).then(result => {
      console.log('returning', {result})
      clearTimeout(timeoutHandle);
      return result;
  })
}
*/

const updateSaasNicknameMutation = gql`
  mutation UpdateNickname(
    $req: UpdateConnectionNickNameRequest!) {
    update_nickname(req: $req) {
      saas
      connection_id
      nick_name
    }
  }
`;

export const updateSaasNickname = async (
  parameters: any
) => {
  const {
    auth0HasuraAccessToken,
    saas,
    nickname, // = {nickname: ''},
    connectionId,
  } = parameters
  console.log({auth0HasuraAccessToken})
  console.log({nickname})
  console.log({connectionId})

  const saasIdentifier = saas.identifier;

  if (!saasIdentifier) throw new Error('saas required');
  if (!connectionId) throw new Error('connectionId required');

  const client = await graphQLClient({
    accessToken: auth0HasuraAccessToken
  });

  const variables = {
    req: {
      saas: saasIdentifier,
      nick_name: (nickname || ''),
      connection_id: connectionId,
    }
  };

  let result = {};

  try {
    // console.log('updateSaasNickname - starting query')
    const {
      data,
      errors,
      // extensions,
      // headers,
      // status
    } = await client?.rawRequest(
          updateSaasNicknameMutation,
          variables
        )
    // console.log('updateSaasNickname - ending query', {data});

    if (errors) {
      console.error('updateSaasNickname throwing error', {errors})
      throw(errors)
    }
    result = data
  }
  catch (error) {
    console.error('updateSaasNickname rethrowing', {error});
    throw(error);
  }

  // console.log(JSON.stringify(result, undefined, 2));

  return result;
}
