import {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Box} from '@mui/material';
import {FinishSetupPanel} from './Panels/FinishSetupPanel';
import {SaasPanel} from './Panels/SaasPanel/SaasPanel';
import {getAdminPathFromLocation} from 'src/utils/other';
import {DomainPanel} from "./Panels/DomainPanel/DomainPanel";
import {NotificationPanel} from './Panels/NotificationPanel';
import {AdminNavigation, adminPanelUrlFragments} from "./AdminNavigation";
import {DtxSpacer} from "../../components/common/DtxSpacer";
import {PortalUsersPanel} from "./Panels/PortalUsersPanel/PortalUsersPanel";
import {ClientsPanel} from "./Panels/ClientsPanel/ClientsPanel";

export const AdminComponent = ({
  allSaases, connectedSaases, userRole,
}: {
  allSaases: any[], connectedSaases: any[], userRole: string,
}) => {
  const {pathname} = useLocation();

  const defaultPanel = useMemo(() => (
    <SaasPanel
      connectedSaases = {connectedSaases}
      allSaases = {allSaases}
    />
  ), [allSaases, connectedSaases])

  const [currentAdminPanel, setCurrentAdminPanel] = useState(defaultPanel
  );

  // reacts to pathname changes so we can do our own panel "routing"
  useEffect(() => {
    // Get the segments of the pathname after admin/
    const pathSegments = getAdminPathFromLocation(pathname);

    // The first element of the pathSegments will be the base path for the admin panel
    // which will decide which component to show
    const adminRouteBaseSegment = pathSegments.length >= 0 ? pathSegments[0] : '';
    switch (adminRouteBaseSegment) {
      case adminPanelUrlFragments.saas:
        setCurrentAdminPanel(defaultPanel)
        break;
      case adminPanelUrlFragments.notifications:
        setCurrentAdminPanel(<NotificationPanel/>)
        break;
      case adminPanelUrlFragments.finishConnectionSetup:
        // The connection id is the 2nd path segment for this page
        if (pathSegments.length >= 1){
          // Make sure it has a connectionID and invoke the component
          setCurrentAdminPanel(<FinishSetupPanel connectionId={pathSegments[1]} connectedSaases = {connectedSaases} />)
        } else {
          // There's no connectionID so show the default panel
          setCurrentAdminPanel(defaultPanel)
        }
        break;
      case adminPanelUrlFragments.domain:
        setCurrentAdminPanel(<DomainPanel/>)
        break;
      case adminPanelUrlFragments.users:
        setCurrentAdminPanel(<PortalUsersPanel userRole={userRole}/>)
        break;
      case adminPanelUrlFragments.clients:
        setCurrentAdminPanel(<ClientsPanel userRole={userRole}/>)
        break
      default:
        setCurrentAdminPanel(defaultPanel)
    }
  }, [allSaases, connectedSaases, defaultPanel, pathname, userRole]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        // why doesn't this work?
        // minWidth: '100vw',
      }}
    >
      <AdminNavigation userRole={userRole} />
      <DtxSpacer orientation="vertical" />
      <Box>
        {currentAdminPanel}
      </Box>
    </Box>
  );
};
