import {TextField} from "@mui/material";
import {isFieldValueNonEmpty} from "./validationFunctions";
import {
  ValidatableComponentProps,
  ValidatedComponent
} from './ValidatedComponent';

// Text field with sweet validation behavior
const ValidatableTextField = ({
  valueKey,
  type,
  values,
  onChange,
  placeholder,
  localValidState,
  label,
  onBlur,
  onFocus,
  InputProps,
  fullWidth = true,
  sx = {}
}: ValidatableComponentProps) => {
  return <TextField
    name={valueKey}

    // style
    size="small"
    type={type}
    fullWidth={true}
    sx={sx}

    // value
    value={valueKey ? (values[valueKey] || '') : ''}
    onChange={onChange}
    placeholder={placeholder}

    // validation
    error={
      !localValidState
    }
    label={
      label
    }
    onBlur={onBlur}
    onFocus={onFocus}
    InputProps={InputProps}
  />;
}

type props = {
  valueKey: string;
  type?: string;
  placeholder?: string;
  validationFunction?: any;
  errorLabel?: string;
  InputProps?: any;
  sx?: any;
  fullWidth?: boolean
}

// General purpose input field with sweet validation behavior
// Based on ValidatedComponent.
// Extended by Secret Field for passwords etc
export const ValidatedField = ({
  validationFunction = isFieldValueNonEmpty,
  valueKey = 'email',
  type = "text",
  placeholder = 'Administrator email',
  errorLabel = 'required',
  InputProps,
  fullWidth,
  sx = {},
}: props) => {
  return (
    <ValidatedComponent
      ValidatableComponent={ValidatableTextField}
      validationFunction={validationFunction}
      valueKey={valueKey}
      type={type}
      placeholder={placeholder}
      errorLabel={errorLabel}
      InputProps={InputProps}
      sx={sx}
      fullWidth={fullWidth}
    />
  )
};
