import {gql} from 'graphql-request';

// "AddConnectionRequest" accepts different objects depending
// on the chosen saas.

export const addConnection = gql`
  mutation AddConnection(
    $req: AddConnectionRequest!
  ) {
    add_connection(req: $req) {
      saas
      saas_id
    }
  }
`;

// Creates a redirect for simple OAuth SaaS connections
// also used to renew connections
export const getOAuthConnectionRedirectMutation = gql`
  mutation GetOAuthConnectionRedirect(
    $saasName: String!, 
    $tenantId: String,
    $connectionId: uuid
  ) {
    create_auth_url(
      saas: $saasName, 
      url: $tenantId,
      connection_id: $connectionId
    ) {
      url
    }
  }
`;

// for Employment Hero second step in the OAuth connection
export const createLinkTokenMutation = gql`
  mutation CreateLinkToken(
    $saas_name: String!, 
    $connection_id: uuid!
  ) {
    create_link_token(
      saas: $saas_name, 
      connection_id: $connection_id
    ) {
      saas
    }
  }
`;
