import memoizee from 'memoizee';
import {SaasHealthRating, SaasIdentifier} from 'src/types/Saas';

// case-insensitive compare two strings
const compareInsensitive = (
  str1: string | null,
  str2: string | null
) => {
  return str1 != null && str2 != null
    && typeof str1 === "string" && typeof str2 === "string"
    && str1.toUpperCase() === str2.toUpperCase();
}

const errorColorIndex = 'error';
const warningColorIndex = 'warning';
const successColorIndex = 'success';

const isExceptionSaas = (saasIdentifier?: string) => {
  return saasIdentifier === SaasIdentifier.xero ||
    saasIdentifier === SaasIdentifier.box;
}

const hpuCountCriticalMin = 2;
const hpuCountCriticalMax = 6;
const hpuCountModerateMax = 4
const puCountMin = 2;
const puCountMax = 4

export const calculateHpuRating = memoizee((hpuCount: number, saasIdentifier?: string) => {
  if (isExceptionSaas(saasIdentifier)) {
    return SaasHealthRating.GOOD;
  }

  let result = SaasHealthRating.GOOD
  if (hpuCount < hpuCountCriticalMin || hpuCount > hpuCountCriticalMax) {
    result = SaasHealthRating.CRITICAL;
  }
  else if (hpuCount > hpuCountModerateMax) {
    result = SaasHealthRating.MODERATE;
  }
  return result;
});

export const calculatePuRating = memoizee((puCount: number) => {
  let result = SaasHealthRating.GOOD
  if (puCount > hpuCountCriticalMax) {
    result = SaasHealthRating.CRITICAL;
  }
  else if (puCount > puCountMax) {
    result = SaasHealthRating.MODERATE;
  }
  return result;
});

// Warning: don't set a result and return that. TypeScript
// raises a seemingly unsolvable error when the code then
// uses the result to index the theme pallete.  Geez.
export const colorForRating = (rating: string) => {
  if (compareInsensitive(rating, SaasHealthRating.CRITICAL)) {
    return errorColorIndex;
  }
  else if (compareInsensitive(rating, SaasHealthRating.MODERATE)) {
    return warningColorIndex;
  }
  return successColorIndex;
}

export const getHpuColor = memoizee((hpuCount: number, saasIdentifier?: string)  => {
  const rating = calculateHpuRating(hpuCount, saasIdentifier);
  return colorForRating(rating);
});

export const getHpuPillText = (hpu: number, saasIdentifier?: string) => {
  // The rating codes just happen to match the desired pill text
  return calculateHpuRating(hpu, saasIdentifier);
};

export const getHpuSubText = memoizee((hpuCount: number, saasIdentifier?: string) => {
  const good = 'Good';
  if (isExceptionSaas(saasIdentifier)) {
    return good;
  }

  if (hpuCount > puCountMax) {
    return 'Reduce to < 5';
  }

  if (hpuCount < puCountMin) {
    return 'Increase to > 2';
  }

  return good;
});

export const getPuColor = memoizee((puCount: number) => {
  const rating = calculatePuRating(puCount);
  return colorForRating(rating);
});

export const getPuPillText = memoizee((puCount: number) => {
  // The rating codes just happen to match the desired pill text
  return calculatePuRating(puCount);
});

export const getPuSubText = memoizee((privilegedUser: number) => {
  if (privilegedUser > puCountMax) {
    return 'Reduce to < 5';
  }
  return 'Good';
});