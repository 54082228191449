import {gql} from 'graphql-request';
import {saasFragment} from 'src/services/shared/Fragments';
import {userStatusStrings} from 'src/utils/constants';

export const connectedSaasesQuery = gql`
  query GetConnectedSaases {
    client_saas (
      where: {saas: {}}, # filter out disabled SaaSes
      order_by: {saas: {name: asc}}
    ) {
      saas  {
        ...Saas
        latest_saas_users(where:{status: {_eq: ${userStatusStrings.enabled}}}){
          privilege_level
        }
      }
      pub_id # the connection id
      nick_name
    }
  }
  ${saasFragment}
`;

export const getAllSaasQuery = gql`
  query AllSaas {
    saas (order_by: {name: asc}) {
      pub_id
      name
      description
    }
  }
`;
