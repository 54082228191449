import React, {useCallback, useState} from 'react';
import {
  CustomFooter,
  DefaultGrid,
  GridHeadingWithExport,
  renderYesNoFromBoolean,
  renderPortalUserRole,
} from "../../../../components/common/Tables/DefaultGrid";
import {IPortalUser} from "../../../../types/PortalUser";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {DefaultButton} from "../../../../components/common/DefaultButton";
import {EditPortalUserDialog} from "./EditPortalUserDialog";
import {renderPortalUserEllipsis} from "./PortalUserActionMenu";
import {useMutationWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {useQueryClient} from "@tanstack/react-query";
import {updatePortalUser, createPortalUser} from "../../../../services/PortalUsers/PortalUserQueries";
import {useErrorDialog} from "../../../../components/common/Dialog/ErrorDialog";
import {ICompany} from "../../../../types/Company";
import {usePasswordSetDialog} from "./usePasswordSetDialog";
import {Auth0UsernamePasswordConnection} from "../../../../utils/constants";
import {Pill} from "../../../../components/common/PIll";
import {theme} from "../../../../theme";


export const PortalUsersComponent = ({
  portalUsers,
  userRole,
  companies
   }: {
    portalUsers: IPortalUser[],
    userRole: string,
    companies: ICompany[],
}) => {
  const minRowsPerPageOption = 100
  const rowsPerPageOptions = [minRowsPerPageOption]

  const queryClient = useQueryClient();

  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false);
  const [isUserUpdatePending, setIsUserUpdatePending] = useState(false);

  const [currentPortalUser, setCurrentPortalUser] = useState<IPortalUser>();

  // Construct the error dialog.
  const {
    dialog: errorDialog,
    setIsOpen: setIsErrorDialogOpen,
    setErrorMessage,
  } = useErrorDialog({
    onDismiss: ()=>{},
  })

  // Construct the password dialog
  // It only appears if "Force password refresh" isn't checked when creating a new user
  const {
    dialog: passwordSetDialog,
    setIsOpen: setIsPasswordSetDialogOpen,
    setPassword: setPasswordInDialog,
  } = usePasswordSetDialog({
    onConfirm: ()=>{
      // Remove the password so it doesn't appear next time
      setPasswordInDialog('');
      },
  })


  const {
    mutate: doUpdateUser,
  } = useMutationWithAccessToken({
    mutation: updatePortalUser,
    tanstackOptions: {
      onSuccess: () => {
        // Invalidating the cache will cause the users to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.portalUsers]);
        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
        setIsErrorDialogOpen(false);
        setErrorMessage('');
      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        setErrorMessage("Could not update user");
        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
      }
    }
  });

  const {
    mutate: doCreateUser,
  } = useMutationWithAccessToken({
    mutation: createPortalUser,
    tanstackOptions: {
      onSuccess: (response: any) => {
        // Invalidating the cache will cause the users to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.portalUsers]);
        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
        setCurrentPortalUser(undefined);

        if (response.insert_user.temp_password) {
          setIsPasswordSetDialogOpen(true);
          setPasswordInDialog(response.insert_user.temp_password);
        }

      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        if (error.response && error.response.errors && error.response.errors.length > 0) {
          setErrorMessage(error.response.errors[0].message);
        }
        else {
          setErrorMessage("Could not add user");
        }

        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
      }
    }
  });

  const onNewUser = () => {
    setCurrentPortalUser(undefined);
    setIsUpdateUserDialogOpen(true)
  }

  const onUpdateUserMenuClick = (user: IPortalUser) => {
    setCurrentPortalUser(user);
    setIsUpdateUserDialogOpen(true)
  }

  const onCancelUpdateUser = () => {
    setIsUserUpdatePending(false);
    setCurrentPortalUser(undefined);
    setIsUpdateUserDialogOpen(false);
    setCurrentPortalUser(undefined);
  }

  const onConfirmUpdateUser = (user: any) => {
    setIsUserUpdatePending(true);
    if (currentPortalUser) {
      // @ts-ignore
      doUpdateUser({portalUser: user});
    } else {
      // @ts-ignore
      doCreateUser({portalUser: user});
    }
  }

  const columns = useCallback(
    (): GridColDef [] => {
      return [
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 200,
          flex: 3,
          sortable: true,

        },
        {
          headerName: 'Email',
          field: 'email',
          flex: 3,
          sortable: true,
        },
        {
          headerName: 'Type',
          field: 'connectionType',
          flex: 1,
          sortable: true,
          renderCell:(params: GridRenderCellParams) => {
            let isSSO = params.row.connectionType !== Auth0UsernamePasswordConnection;
            let pillColor = isSSO ? theme.more.andyBlue : theme.more.andyGreen;
            let pillTitle = isSSO ? "SSO" : "PWD";
            let pillDescription = isSSO ? "Detexian SSO authentication" : "Password authentication";
            return (<Pill
              pillWidth="82px"
              pillColor={pillColor}
              title={pillTitle}
              hoverText={pillDescription}>
            </Pill>)
          },
        },
        {
          headerName: 'Company',
          field: 'companyName',
          flex: 3,
          sortable: true,
        },
        {
          headerName: 'Role',
          field: 'role',
          flex: 2,
          sortable: true,
          valueGetter: (params) => renderPortalUserRole(params),
        },
        {
          headerName: 'Disabled',
          field: 'disabled',
          flex: 1,
          sortable: true,
          valueGetter: (params) => renderYesNoFromBoolean(params),
        },
        {
          headerName: '',
          field: 'action',
          renderCell: (params: GridRenderCellParams) =>
            renderPortalUserEllipsis({
              userRole: userRole,
              portalUser: params.row,
              onUpdateClick: onUpdateUserMenuClick
            }),
          sortable: false,
          disableExport: true,
          disableReorder: true,
          filterable: false,
          disableColumnMenu: true,
          flex: 1,
        },
      ];
    },
    [userRole]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: minRowsPerPageOption,
    page: 0,
  });

  return (<>
      <EditPortalUserDialog
        portalUser={currentPortalUser}
        isOpen={isUpdateUserDialogOpen}
        setIsOpen={setIsUpdateUserDialogOpen}
        isSpinning={isUserUpdatePending}
        onCancel= {onCancelUpdateUser}
        onConfirm={onConfirmUpdateUser}
        userRole={userRole}
        companies={companies}
      />
      {errorDialog}
      {passwordSetDialog}
        <DefaultGrid
          rows={portalUsers}
          getRowId={row => row.id}
          columns={columns()}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={rowsPerPageOptions}
          length={portalUsers?.length}
          minRowsPerPageOption={minRowsPerPageOption}
          initialState={{
            sorting: {
              sortModel: [{
                field: "name",
                sort: "asc",
              }],
            },
          }}
          toolbar={() =>
            <GridHeadingWithExport
              heading="Users"
              shouldShowExportButton={false}
              shouldShowBackToPrevious={false}
              tableDescription={<><DefaultButton
                sx={{
                  width: '7rem',
                  fontWeight: '500',
                  float: 'right',
                  marginTop: '-32px',
                }}
                autoFocus
                variant="contained"
                onClick={onNewUser}
              >
                Add user
              </DefaultButton>
              <p>Control how users access the Detexian portal.</p>
            </>}
            />}
          footer={() => CustomFooter({})}
        />
    </>
  )
};
