import {gql} from 'graphql-request';

// Gets a list of portal users that the logged in user has access to
export const portalUserQuery = gql`
query ListPortalUsers {
    list_users{
          id
          name
          email
          client_id
          client {
            name
          }
          disabled
          role
          connection_type
      }
}`;

// Mutation for updating a portal user
export const updatePortalUserMutation = gql`
mutation UpdatePortalUser($req:UpdateUserRequest!){
  update_user(req:$req) {
    name
    email
  }
}`;

export const createPortalUserMutation = gql`
mutation CreatePortalUser($req:CreateUserRequest!){
  insert_user(req:$req) {
    id
    temp_password
  }
}`;