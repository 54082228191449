import {Typography, useTheme} from '@mui/material';
import {SaasStatus} from 'src/types/Saas';
import {getStatusColor} from 'src/utils/SiteModuleHelper';
import {useSaasStatusBadgeStyles} from './styles';

type SaasStatusProp = {
  status: SaasStatus;
};

export function SaasStatusBadge({ status }: SaasStatusProp): JSX.Element {
  const { rootStyles, textStyles } = useSaasStatusBadgeStyles();
  const {
    palette: { error, success, warning },
  } = useTheme();

  const statusColor = getStatusColor(status, {
    error: error.main,
    warning: warning.main,
    success: success.main,
  });

  return (
    <div className={rootStyles} style={{ backgroundColor: statusColor }}>
      <Typography className={textStyles} component="span">
        {status}
      </Typography>
    </div>
  );
}
