import {useCallback, useState} from 'react';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {getAllSaas, getConnectedSaases} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {AdminComponent} from './AdminComponent';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {useAuth0Token} from "../../hooks/Auth0Hooks";
import {portalUserRoles} from "../../utils/constants";
import {ISaas} from "../../types/Saas";

// handles data aquisition and decides whether to
// return the page, loading or error component
export const AdminPage = () => {
  const documentTitle = 'Admin - Detexian';
  document.title = documentTitle;

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const {niceClaims, isLoading} = useAuth0Token();
  // Get the role of the user, default it to "user" if it is still loading
  let hasRole = (!isLoading && niceClaims !== undefined && niceClaims.resolvedRole !== undefined)
  let userRole = hasRole ? niceClaims.resolvedRole : portalUserRoles.user;

  // SSPM-999: Temporary filter while clients convert from Gsuite to GoogleWS.
  const getAllFilteredSaas = async (accessToken?: any): Promise<ISaas[]> => {
      const response = await getAllSaas(accessToken);
      return response.filter((s: ISaas) => s.identifier !== 'gsuite')
  }

  const {data: allSaases} = useLegacyQueryWithToken(
    [cacheKeys.filteredAllSaasList],
    getAllFilteredSaas,
    {},
    {
      enabled: hasRole,
      onError: () => {
        setUserErrorMessge('error accessing all saases');
      }
    }
  );

  const queryResult = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
    {},
    {
      enabled: !!allSaases,
      onError: () => {
        setUserErrorMessge('error accessing connected saases');
      }
    }
  );

  // can sometimes show a table with no rows
  // this log is an attempt to diagnose
  // console.log({allSaases}, {queryResult});

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () =>
      <AdminComponent
        allSaases={allSaases}
        connectedSaases={queryResult.data}
        userRole={userRole}
      />
    ,
    [allSaases, queryResult.data, userRole]
  );

  return useComponentForQueryResult({
    queryResult: queryResult,
    pageLoadFunction,
    userErrorMessage,
    errorMessageDoesIndicateError: true
  })
};
