import {
  companyDomainsQuery,
    deleteCompanyDomainMutation,
  addCompanyDomainMutation,
} from 'src/services/Domains/DomainGraphQL';

import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {ICompanyDomain} from "../../types/CompanyDomain";
import {graphQLClient} from "../shared/GraphQLService";

// A type of represent the GraphQL response
interface IDomainResponse {
  pub_id: string;
  name: string;
}
export const getCompanyDomains = async (
  accessToken: any,
): Promise<ICompanyDomain[]> => {
  const theQuery = async (client: any) => {
    const result = client.request(
        companyDomainsQuery,
      {
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  }
  catch (error) {
    console.log('getCompanyDomains failed')
    throw (error)
  }

  // The GraphQL response will contain an Array of clients, but it
  // should only ever include the client that is authenticated
  if(response?.client?.length !== 1) {
    console.log('Exactly one client should be returned');
    throw new Error('Exactly one client should be returned');
  }

  // If is safe to get the first client now
  const client = response?.client[0];

  // Get the registered domains
  const registeredDomains = client.registered_domains.map((
      {
        pub_id,
        name
      }:IDomainResponse
  ) => {
    // Map the response from the API (IDomainResponse) to an ICompanyDomain type
    return {
      id: pub_id,
      name,
      registered: true
    }
  });

  // Get the discovered domains
  const discoveredDomains = client.discovered_domains.map((
      {
        pub_id,
        name
      }:IDomainResponse
  ) => {
    // Map the response from the API (IDomainResponse) to an ICompanyDomain type
    return {
      id: pub_id,
      name,
      registered: false}
  });

  // Join registered and discovered together
  // The returns an array of ICompanyDomain object which is what the Front End uses from now on
  return registeredDomains.concat(discoveredDomains);
}


export const deleteCompanyDomain = async (
    parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    domain
  } = parameters;

  if (!domain) throw new Error('domain required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    req: {
      id: domain.id
    }
  };
  const result = await client?.request(
      deleteCompanyDomainMutation,
      variables
  )

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message)
  };

  return result;
}

export const addCompanyDomain = async (
    parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    domainName
  } = parameters;

  if (!domainName) throw new Error('domain name required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    req: {
      domain_name: domainName
    }
  };
  const result = await client?.request(
      addCompanyDomainMutation,
      variables
  )


  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message)
  };

  return result;
}

