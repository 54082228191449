import {graphQLClient} from 'src/services/shared/GraphQLService';
import {
  addConnection,
  createLinkTokenMutation,
  getOAuthConnectionRedirectMutation,
} from 'src/services/SaasService/AddSaasMutations';

//
// Mutations - Add SaaS
//

// mutation for non-OAuth SaaS connections
export const addSaaSConnection = async (
  accessToken: string,
  parameters: any,
) => {
  const {
    saas, // : string | undefined,
    ...connectionParameters // : string | null,
  } = parameters

  if (!saas) throw new Error('saas required');

  // console.log('addSaaSConnection', {connectionParameters})
  const client = await graphQLClient({accessToken});

  const variables = {
    req: {
      saas: saas.identifier,
      ...connectionParameters,
    }
  };
  const result = await client?.request(
    addConnection,
    variables
  )

  // console.log(JSON.stringify(result, undefined, 2));

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message)
  };

  return result;
}

export const getOAuthConnectionRedirect = async (
  parameters: any,
) => {
  console.log('running getOAuthConnectionRedirect', {parameters});

  const {
    auth0HasuraAccessToken,
    saas,
    tenantId,
    connectionId,
  } = parameters;

  console.log('getOAuthConnectionRedirect', {auth0HasuraAccessToken}, {saas}, {tenantId}, {connectionId})

  let result;
  try {
    const client = await graphQLClient({accessToken:auth0HasuraAccessToken});

    const variables = {
      saasName: saas.identifier,
      tenantId,
      connectionId, // to keep typescript happy
    };
    result = await client?.request(
      getOAuthConnectionRedirectMutation,
      variables
    )
  }
  catch (error) {
    console.error(error)
    throw error;
  }

  // console.log(JSON.stringify(result, undefined, 2));

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message);
  }

  return result.create_auth_url.url;
}

// mutation for Employment Hero second step in the OAuth connection
export const createLinkToken = async (
  accessToken: string,
  parameters: any,
) => {
  const {
    saasIdentifier, // : string | undefined,
    connectionId // : string | null,
  } = parameters
  // console.log('createLinkToken', {accessToken}, {saasIdentifier}, {connectionId})
  const client = await graphQLClient({accessToken});

  const variables = {
    saas_name: saasIdentifier,
    connection_id: connectionId,
  };

  const result = connectionId && (await client?.request(
    createLinkTokenMutation,
    variables
  ))

  // console.log(JSON.stringify(result, undefined, 2));

  if (result.error) throw new Error(result.error.message);
  if (result === undefined) throw new Error('missing connection_id');

  return result?.create_link_token.url;
}




// Might want this for improving TypeScript support
/*
interface ISaasUserGraphQLResponse {
  last_login: string;
  pub_id: string; // this is not the generic_user.pub_id
  created_time: string | null;
  inactive_60days: boolean;
  privilege_level: string;
  mfa_status: boolean;
  generic_user: { // still needed for pub_id
    name: string;
    email: string;
    pub_id: string;
  };
  name: string; // replaces generic_user.name in the new query
  email: string; // replaces generic_user.email in the new query
  domain: string;
}
*/


