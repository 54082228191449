// import CloseIcon from '@mui/icons-material/Close';
import {Box} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {StyledMoreVertIcon} from "src/components/common/Tables/DefaultGrid/StyledMoreVertIcon";

// custom content, usually a menu, within the ellipsis menu
// must inherit from this interface
export interface ellipsisMenuProps {
  anchorEl: any,
  open: boolean,
  doOpenMenu: any,
  handleClose: any,
}

const useEllipsisMenu = (EllipsisMenuContents: any, menuProps: any) => {
  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null);
  const open = Boolean(anchorEl);

  const doOpenMenu = (event: any) => {
    // console.log({event});
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  // wrapping this in a useCallback prevented a "flicker"
  // when rendering the menu for the first time
  const LoadedMenu = useCallback(() => {
    return EllipsisMenuContents({
      // General menu control
      anchorEl,
      open,
      doOpenMenu,
      handleClose,

      // specific action menu props
      ...menuProps,
    });
  }, [EllipsisMenuContents, anchorEl, menuProps, open]);

  return {
    EllipsisMenu: LoadedMenu,
    doOpenMenu,
  };
};

export const EllipsisRender = ({
  menu, menuProps
}: {
  menu: any, menuProps: any
}) => {
  // console.log({menuProps})
  const {
    EllipsisMenu,
    doOpenMenu,
  } = useEllipsisMenu(menu, menuProps);

  return (
    <Box>
      <StyledMoreVertIcon
        onClick={doOpenMenu}
      />
      <EllipsisMenu />
    </Box>
  );
};

export const actionColumnLabel = 'Action';

// obsolete, but interesting, menu items
       // {/*<MenuItem onClick={() => navigate(userRoute(user.pubId))}>*/}
       //  {/*  Go to User View*/}
       //  {/*</MenuItem>*/}
       //
       //  {/*<Divider />*/}
       //  {/*<MenuItem onClick={() => navigate(userRoute(user.pubId))}>*/}
       //  {/*  {`Status: ${user.status}`}*/}
       //  {/*</MenuItem>*/}
       //
       //  {/*<MenuItem onClick={handleClose}>*/}
       //  {/*  <Box*/}
       //  {/*    sx={{*/}
       //  {/*      display: 'flex',*/}
       //  {/*      justifyContent: 'space-between',*/}
       //  {/*      alignItems: 'center',*/}
       //  {/*      gap: '13px',*/}
       //  {/*    }}*/}
       //  {/*  >*/}
       //  {/*    <CloseIcon sx={{fontSize: iconSize}}/>*/}
       //  {/*    <div>Close</div>*/}
       //  {/*  </Box>*/}
       //  {/*</MenuItem>*/}
