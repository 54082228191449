import {graphQLClient} from 'src/services/shared/GraphQLService';
import {gql} from "graphql-request";

const removeConnectionMutation = gql`
  mutation RemoveConnection(
    $req: DeleteConnectionRequest!
   ) {
    delete_connection(req: $req) {
      saas
    }
  }
`;

export const removeConnection = async (
  parameters: any
) => {
  // console.log({parameters})
  const {
    auth0HasuraAccessToken,
    saas,
    connectionId,
  } = parameters

  const saasIdentifier = saas.identifier;

  // for testing without removal return; // throw new Error('hoohah')

  if (!saasIdentifier) throw new Error('saas required');
  if (!connectionId) throw new Error('connectionId required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    req: {
      saas: saasIdentifier,
      connection_id: connectionId,
    }
  };

  let result;
  // temporary logic to avoid removing on localhost and uat
  // if (
  //   isLocalhost() ||
  //   (process.env.REACT_APP_INSTANCE_TAG === 'uat')
  // ) {
  //   console.log('localhost, so not removing', saasIdentifier, 'with connectionId', connectionId);
  //   result = {saasIdentifier};
  // }
  // else {
    result = await client?.request(
      removeConnectionMutation,
      variables
    )
  // }

  // console.log(JSON.stringify(result, undefined, 2));

  if (result.error) throw new Error(result.error.message);

  return result;
}
