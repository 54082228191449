import React from 'react';
import {riskTypeIdentifiers} from "../../../routes";
import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {usePortalContext} from "../../../hooks/PortalContext";
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {getPanelLink, performFeatureBasedQuery} from "../DashboardPanels/panelCommon";
import {DonutDriftChart} from "./DonutDriftChart";
import {DefaultContainer} from "../../../components/common/DefaultContainer";
import {saasCoverageCount} from "../../../utils/saasUtils";
import {getEnabledUsersCount} from "../../../services/shared/EnabledUsers";
import {getMFADailyDrifts} from "../../../services/DriftService/DailyDriftService";
import {countMFADrift} from "../../../components/DriftGraph/DailyDriftDataCounters";
import {getMfaDisabled, getMfaDisabledCount} from "../../../services/shared/MFADisabled";

export const MFADisabledChart = (props: FeatureCountProps) => {
  const allUsersQuery = useLegacyQueryWithToken(
    [cacheKeys.allEnabledUsersCount],
    getEnabledUsersCount,
    {},
    {
    }
  );

  const {isLoading: mfaDriftLoading, data: mfaDrifts} = useLegacyQueryWithToken(
    [cacheKeys.dailyDrifts.mfa],
    getMFADailyDrifts,
    {},
    {
    }
  );

  // This query is executed if the feature is turned off - it gets the count of inactive users
  const countQuery = useLegacyQueryWithToken(
    [cacheKeys.mfaDisabledCount],
    getMfaDisabledCount,
    {},
    {
      enabled: !props.isFeatureOn
    }
  );

  // This query is executed if the feature is turned on - it gets the full list of inactive users
  const fullQuery = useLegacyQueryWithToken(
    [cacheKeys.mfaDisabled],
    getMfaDisabled,
    {},
    {
      enabled: props.isFeatureOn
    }
  );

  const {isLoading: isCurrentStateLoading, count, users} = performFeatureBasedQuery(props.isFeatureOn, fullQuery, countQuery);

  // The page is loading if any of the queries are loading
  let isLoading = allUsersQuery.isLoading || mfaDriftLoading || isCurrentStateLoading;

  const {isTrial} = usePortalContext();

  const theSaasCoverageCount = saasCoverageCount(users);

  let mfaSeries = [{
    name: "MFA",
    color: "#82ca9d",
    start: count,
    data: mfaDrifts,
    countFn : countMFADrift
  }];

  return (<DefaultContainer
    sx={{
      display: 'flex',
      marginBottom: 0,
      maxWidth: '49%',
      minWidth: '49%',
    }}
  >
    <DonutDriftChart
      title={"MFA Disabled"}
      count={count}
      outOf={allUsersQuery.data}
      appCount={theSaasCoverageCount}
      description={<span><em>Users who have <strong>not </strong> yet enabled <strong>Multi-Factor Authentication</strong></em></span>}
      driftSeries={mfaSeries}
      isFeatureOn={props.isFeatureOn}
      isLoading={isLoading}
      viewAllUrl={getPanelLink(riskTypeIdentifiers.mfaDisabled, count, !isTrial && props.isFeatureOn)}/>
  </DefaultContainer>)
};
