import {graphQLClient} from 'src/services/shared/GraphQLService';
import {gql} from "graphql-request";

const updateConnectionMutation = gql`
  mutation UpdateConnection(
    $req: AddConnectionRequest!
  ) {
    update_connection(req: $req) {
      saas
    }
  }
`;

export const updateConnection = async (
  parameters: any
) => {
  console.log({parameters})
  const {
    auth0HasuraAccessToken,
    saas,
    connectionId,
    ...fieldValues
  } = parameters

  const saasIdentifier = saas.identifier;

  if (!saasIdentifier) throw new Error('saas required');
  if (!connectionId) throw new Error('connectionId required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    req: {
      saas: saasIdentifier,
      connection_id: connectionId,
      ...fieldValues
    }
  };

  console.log({variables});

  const result = await client.request(
    updateConnectionMutation,
    variables
  )

  console.log(JSON.stringify(result, undefined, 2));

  if (result.error) throw new Error(result.error.message);

  return result;
}
