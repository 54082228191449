import React, {FC} from 'react';
// import {signal} from "@preact/signals-react";
import {Box, Checkbox, FormControlLabel, styled, Typography} from '@mui/material';
import {BackToPrevious} from 'src/components/common/BackToPrevious';
import {useAuth0Token} from 'src/hooks/Auth0Hooks';
import {useFilterMenu} from 'src/pages/ActivityLogPage/FilterMenu';
 import {
  generalBorder,
  linkStyleFragment,
  tableContainerBorderRadius
} from 'src/theme';
import {useLocalStorage} from 'src/hooks/useLocalStorage';
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {isDemoEnvironment, isLocalhost} from 'src/utils/other';

// const signalTest = signal(0); // this breaks the router ?!?

const OuterBox = styled(Box)(
  ({theme}) => ({
   width: '800px',
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    padding: '15px 32px 32px 32px',
    borderRadius: theme.more.borderRadius,
 })
);

const SettingsBox = styled(Box)(
  ({theme}) => ({
    fontFamily: 'Lato, serif',
    color: 'hsl(0, 0%, 34%)',
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    padding: '30px 50px 30px 60px',
    border: generalBorder,
    borderRadius: tableContainerBorderRadius,
    'a': {...linkStyleFragment}
 })
);

const Option = (props: {
  checked: boolean,
  onChange: () => void,
  label: string
}) => {
  return <FormControlLabel
    sx={{
      flex: '0 0 17%',
    }}
    control={
      <Checkbox
        name={props.label}
        checked={
          props.checked
        }
        onChange={
          props.onChange
        }
        inputProps={{'aria-label': props.label}}
      />
    }
    label={props.label}
  />;
}

const companiesDefaultOption = {
  id: 'defaultCompany',
  value: '',
  label: 'Default Company'
};

export const SettingsPage: FC = () => {
  const documentTitle = 'Settings - Detexian';
  document.title = documentTitle;

  // const [
  //   shouldShowIncludeFilters,
  //   setShouldShowIncludeFilters
  // ] = useLocalStorage('shouldShowIncludeFilters', false);

  // const [
  //   hasSignedUp,
  //   setHasSignedUp
  // ] = useLocalStorage('hasSignedUp', false);

  const [
    shouldShowZeroInactiveUsersOnDashboard,
    setShouldShowZeroInactiveUsersOnDashboard
  ] = useLocalStorage('shouldShowZeroInactiveUsersOnDashboard', false);

  const [
    shouldShowExperimentalOnboarding,
    setShouldShowExperimentalOnboarding
  ] = useLocalStorage('shouldShowExperimentalOnboarding', false);

  const [
    shouldUseDarkMode,
    setShouldUseDarkMode
  ] = useLocalStorage('shouldUseDarkMode', false);

  const {niceClaims} = useAuth0Token();

  // company IDs


  const mockCompanies = [
    'Amur Finance',
    'Capbridge SG',
    'Donaldson Brown',
    'Foundation Life NZ',
    'Leapdev AU',
    'Lesmills',
    'Punter Southall Group',
    'UBT Team AU',
    'willowinc',
    'Zoos Vic',
  ]

  const toNiceCompanyName = (companyId: string) => {
    let result = companyId;
    switch (companyId) {
      case 'Leapdev AU':
        result = 'LEAP Dev Pty Ltd'
        break;
      case 'UBT Team AU':
        result = 'UBT Marketing Pty Ltd' // 'Universal Business Team'
        break;
      case 'Lesmills':
        result = 'Les Mills International'
        break;
       case 'willowinc':
        result = 'Willow Inc' // Willow Technology Corporation
        break;
      case 'Zoos Vic':
        result = 'Zoos Victoria'
        break;
      case 'FIRST':
        result = 'FIRST'
        break;
   }
    return result;
  }

  const toGenericCompanyName = (companyId: string) => {
    let result = companyId;
    switch (companyId) {
      case 'Leapdev AU':
        result = 'Legal Technology'
        break;
      case 'UBT Team AU':
        result = 'Marketing'
        break;
      case 'Lesmills':
        result = 'Fitness'
        break;
      case 'Punter Southall Group':
        result = 'Financial Planning'
        break
      case 'willowinc':
        result = 'Digital Replicas'
        break;
      case 'Zoos Vic':
        result = 'Zoos and Parks'
        break;
      case 'Amur Finance':
        result = 'Equipment Finance'
        break;
      case 'Capbridge SG':
        result = 'Capital Investment'
        break;
      case 'Donaldson Brown':
        result = 'Insurance Broker'
        break;
      case 'Foundation Life NZ':
        result = 'Life Insurance'
        break;
     case 'FIRST':
        result = 'Education'
        break;
   }
    return result;

  }

  const companyOptions = () => {
    let result = [companiesDefaultOption];

    if (niceClaims) {
      // console.log({niceClaims});
      let companies = niceClaims?.allowedCompanies;
      // // temp mocking if no companies
      if (!companies || companies?.length < 1) {
        console.log ('no companies, mocking');
        companies = mockCompanies;
      }
      result = result.concat(
        companies.map(
          (company: string) => {
            return {
              label: isDemoEnvironment() ?
                toGenericCompanyName(company) : // per Adrian's request
                toNiceCompanyName(company)
              ,
              id: company,
              value: company,
            }
          }
        )
      )
    }
    // TODO not sure why niceClaims is undefined on anything but localhost
    else if (isDemoEnvironment()) {
      console.log('niceClaims undefined - mocking')
      result = result.concat(
        mockCompanies.map(
          (company: string) => {
            return {
              label: toGenericCompanyName(company),
              id: company,
              value: company,
            }
          }
        )
      )
    }

    // console.log({result});
    return result;
  }

  const {
    filterMenu: CompanyMenu,
    // selectionState: [
    //   companyselectionState,
    //   setCompanyselectionState
    // ]
  } = useFilterMenu({
    label: 'Choose Company',
    options: companyOptions(),
    ariaLabel: 'Company',
    menuId: 'companyMenuId',
  });



  // const companyMenu = useFilterMenu({
  //   label: 'Choose Company',
  //   options: companies,
  //   ariaLabel: 'Company',
  //   menuId: 'companyMenuId',
  // });
  //
  // const CompanyMenu = companyMenu.filterMenu;
  // const [
  //   companyselectionState,
  //   setCompanyselectionState,
  // ] = companyMenu.selectionState;

  // useEffect(
  //   () => setCompanyselectionState('leapdev',
  //   ),[setCompanyselectionState]
  // )

  return (
    <OuterBox>
    <BackToPrevious />
    <DtxSpacer space={1.5}/>
    <SettingsBox>
      {process.env.REACT_APP_ROLE_FEATURE_FLAG === '1' && <>
        <Box
          sx={{
            marginLeft: '-13px',
            width: '220px',
          }}
        >
          <CompanyMenu
            // Get sx working
            // sx={{
            //   width: '120px',
            // }}
          />
        </Box>
        <DtxSpacer space={4} />
      </>}

      {/*<Option*/}
      {/*  checked={hasSignedUp}*/}
      {/*  onChange={*/}
      {/*    () => setHasSignedUp(*/}
      {/*      !hasSignedUp*/}
      {/*    )*/}
      {/*  }*/}
      {/*  label="Has Signedup"*/}
      {/*/>*/}
      {/*<DtxSpacer space={0} />*/}

      <Option
        checked={shouldShowExperimentalOnboarding}
        onChange={
          () => setShouldShowExperimentalOnboarding(
            !shouldShowExperimentalOnboarding
          )
        }
        label="Show experimental user onboarding"
      />

      {
        (isLocalhost()) && <>
          <DtxSpacer space={2} />
          <Typography>
            These only show up in local dev
          </Typography>
          <Option
            checked={shouldShowZeroInactiveUsersOnDashboard}
            onChange={
              () => setShouldShowZeroInactiveUsersOnDashboard(
                !shouldShowZeroInactiveUsersOnDashboard
              )
            }
            label="Show Zero Inactive Users on Dashboard"
          />
          <DtxSpacer space={0} />
          <Option
            checked={shouldUseDarkMode}
            onChange={
              () => setShouldUseDarkMode(
                !shouldUseDarkMode
              )
            }
            label="Experimental Dark Mode - not working"
          />
          <DtxSpacer space={2} />
        </>
      }
    </SettingsBox>
    </OuterBox>
  )
}
