import {
  getGridStringOperators,
  GridColDef,
  GridComparatorFn,
  GridFilterInputValue,
  GridFilterItem,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import React from 'react';
import {SaasLogoAndName} from 'src/components/SaasLogo';
import {FormattedDate} from 'src/components/common/Tables/Utilities';
import {portalUserRoles, privilegeStrings} from 'src/utils/constants';
import {valueOrStringForMissingText} from "src/utils/string";
import {isInactiveDate} from "src/utils/dates";

export const emailValueGetter = ({row}: { row: any }) => {
  // console.log({row});
  return valueOrStringForMissingText(row.user.email);
}

// Adds a "does not contain" operator to the string filter
// operators for the column

// It may be fragile as it depends on a valueGetter existing on
// the column, which may not be the case for simple rows where,
// say, the email address isn't inside a user object.

// It may still work since the logic falls back to params.value
// which may be valid if the column "field" is configured correctly,
// most likely meaning something like field: 'email'
export const appendedFilterOperators = () => {
  const [contains, ...rest] = getGridStringOperators();
  return [
    contains,
    {
      value: 'noContain',
      getApplyFilterFn: (
        filterItem: GridFilterItem,
        column: GridColDef,
      ) => {
        if (
          !filterItem.field ||
          !filterItem.value ||
          !filterItem.operator
        ) {
          return null;
        }
        const filterRegex = new RegExp(filterItem.value, 'i');
        return (params: GridValueGetterParams): boolean => {
          const rowValue = column.valueGetter
            ? column.valueGetter(params)
            : params.value;
          return !filterRegex.test(rowValue?.toString() || "");
        };
      },
      InputComponent: GridFilterInputValue
    },
    ...rest
  ]
}

export const renderSaas = ({
  params,

  // only pass this aregument if its unclear to which
  // multiply connected saas nickname we're referring
  // connectedSaases,

  shouldShowCalculatedName = true,
}: {
  params: GridRenderCellParams,
  // connectedSaases?: ConnectedSaas[],
  shouldShowCalculatedName?: boolean,
}) => {
  // console.log({params})
  const {row} = params;
  // console.log({row}, {connectedSaases});

  // This logic may be too complicated.
  // However it supports rows which:
  // - are connected saases,
  // - are ordinary saases
  // - which contain a saas object

  const saas = !!row?.connectionId ? row : row.saas;

  //     row :

  // const saas = !!row?.connectionId ?
  //     row :
  //     connectedSaases ?
  //   // TODO this might be a bad idea for everything except
  //   //   discovered apps and maybe AllSaas on the Admin Page
  //   firstConnectedSaasForIdentifier(
  //     row.saas.identifier,
  //     connectedSaases
  //   ) : row.saas

  // console.log({saas})
  return (
    <SaasLogoAndName
      saas={saas}
      size="m"
      shouldLinkToSaasView={!row.shouldDisableLink}
      shouldShowCalculatedName={shouldShowCalculatedName}
    />
  );
};

export const renderDateAndTime = (
  params: GridRenderCellParams,
) => {
  return (
    <FormattedDate
      date={params.value}
    />
  );
};



export const renderDate = (
  params: GridRenderCellParams,
  shouldHighlight?: boolean,
) => {
  const date = params.value;
  const highlightInactive = shouldHighlight &&
    isInactiveDate(date);
  return (
    <FormattedDate
      date={params.value}
      shouldShowTime={false}
      shouldHighlight={highlightInactive}
    />
  );
};

// Returns a Yes/No value for a True/False field
export const renderYesNoFromBoolean = (
    params: GridRenderCellParams,
) => {
  return params.value ? "Yes" : "No";
};

// Converts the "Role" - admin, org_admin, user
// to a presentable version for the grid cell
export const renderPortalUserRole = (
  params: GridRenderCellParams,
) => {
  switch(params.value){
    case portalUserRoles.admin:
      return "Super Admin";
    case portalUserRoles.orgAdmin:
      return "Account Admin";
    case portalUserRoles.user:
      return "User";
    default:
      return "-";
  }
};


// Sorting comparators
// export const saasComparator: GridComparatorFn<any> = (v1, v2, p1, p2) => {
//   // console.log({v1}, {v2});
//   return gridStringOrNumberComparator(v1.identifier, v2.identifier, p1, p2);
// };

export const nameComparator: GridComparatorFn<any> = (v1, v2, p1, p2) => {
  // console.log({v1}, {v2});
  return gridStringOrNumberComparator(v1.name, v2.name, p1, p2);
};

export const largerMinRowsPerPageOption = 10;
export const largerRowsPerPageOptions = [largerMinRowsPerPageOption, 20, 30, 50, 100]
export type largerRowsPerPageOptionsType = typeof largerMinRowsPerPageOption | 20 | 30 | 50 | 100;

export const smallerMinRowsPerPageOption = 5;
export const smallerRowsPerPageOptions = [smallerMinRowsPerPageOption, 10, 20, 30, 50, 100]
export type smallerRowsPerPageOptionsType = typeof smallerMinRowsPerPageOption | 10 | 20 | 30 | 50 | 100;

export const privColor = (
  privilegeLevel: string | undefined,
  theme: any,
) => {
  let result = theme.palette.text.primary;
  if (privilegeLevel === privilegeStrings.highlyPrivileged)
    result = theme.palette.error.main;
  else if (privilegeLevel === privilegeStrings.privileged)
    result = theme.palette.warning.main;
  return result;
};

export const mfaColor = (
  mfaStatus: boolean | undefined,
  theme: any,
) => {
  let result = theme.palette.text.primary;
  if (mfaStatus === false)
    result = theme.palette.error.main;
  return result;
};
