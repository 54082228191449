import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';
import memoizee from 'memoizee';
import {portalUserRoles} from "../utils/constants";

const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope = process.env.REACT_APP_AUTH0_SCOPE

export const jwtDecode = (t: string | undefined) => {
  const token = {
    raw: '',
    header: {},
    payload: {},
  }
  if (typeof(t) === 'string') {
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
  }
  return token
};

type decodedAccessTokenType = {
  raw: string;
  header: any;
  payload: any;
}

const detexianAdminRole = portalUserRoles.admin;
const mspAdminRole = portalUserRoles.orgAdmin;
const userRole = portalUserRoles.user;

// decodes the Access Token
// changes the company-list string to an array
// picks the "highest" role
const getNiceClaims = memoizee((accessToken: string | undefined) => {
  const niceAccessTokenClaims: {
    defaultRole: string,
    resolvedRole: string,
    allowedRoles: string[],
    companyId: string,
    allowedCompanies: string[],
    allowedPrimaryDomain: string,
  } = {
    defaultRole: '',
    resolvedRole: '',
    allowedRoles: [],
    companyId: '',
    allowedCompanies: [],
    allowedPrimaryDomain: '',
  };

  const allowedCompaniesArray = (allowedCompaniesString: string) => {
    let result: string[] = [];
    if (typeof(allowedCompaniesString) === 'string') {
      let stringWithoutbrackets = allowedCompaniesString.replace('{', '');
      stringWithoutbrackets = stringWithoutbrackets.replace('}', '');
      result = stringWithoutbrackets.split(',');
    }
    return result;
  }

  if (accessToken) {
    const decodedAccessToken:
      decodedAccessTokenType = jwtDecode(accessToken);

    const accessTokenPayload = decodedAccessToken.payload;
    const accessTokenClaims = accessTokenPayload[
      'https://hasura.io/jwt/claims'
      ];

    niceAccessTokenClaims.defaultRole = accessTokenClaims[
      'x-hasura-default-role'
      ];
    niceAccessTokenClaims.allowedRoles = accessTokenClaims[
      'x-hasura-allowed-roles'
      ];
    niceAccessTokenClaims.companyId = accessTokenClaims[
      'x-hasura-company-id'
      ];
    niceAccessTokenClaims.allowedPrimaryDomain = accessTokenClaims[
      'x-hasura-primary-domain'
      ];

    // convert the companys string e.g.
    //   '{Lab Group Pty Ltd,Detexian Pty Ltd}'
    // into an arrary
    niceAccessTokenClaims.allowedCompanies = allowedCompaniesArray(
      accessTokenClaims[
      'x-hasura-allowed-companies'
      ]
    )

    // set resolvedRole to the "highest" role
    niceAccessTokenClaims.resolvedRole = userRole;

    if (niceAccessTokenClaims.allowedRoles.includes(detexianAdminRole))
      niceAccessTokenClaims.resolvedRole = detexianAdminRole;
    else if (niceAccessTokenClaims.allowedRoles.includes(mspAdminRole))
            niceAccessTokenClaims.resolvedRole = mspAdminRole;

  }
  return niceAccessTokenClaims;
})

type tokenType = {
  idToken:string | undefined;
  accessToken:string | undefined;
  token:string | undefined;
  niceClaims:any
};

export const useAuth0Token = () => {
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isLoading,
    error,
    user
  } : any = useAuth0();

  // Holds the entire state for the returned object. This is so that the state can be updated once the async function is complete
  // and unnecessary re-renders don't need to happen
  const [tokenState, setTokenState] = useState<tokenType>({idToken: undefined, accessToken: undefined, token: undefined, niceClaims: undefined});

  useEffect(
    () => {
      // create an async function to get an ID Token
      // and save the token in state
      const asyncFunction = async () => {
        if (
          !isLoading &&
          !error &&
          typeof getIdTokenClaims === 'function' &&
          typeof getAccessTokenSilently === 'function'
        ) {
          const idToken_ = await getIdTokenClaims();
          const accessToken_ = await getAccessTokenSilently({
            audience,
            scope
          })
          let claims: any;
          if (accessToken_) {
            claims = getNiceClaims(accessToken_);
          }
          setTokenState({idToken: idToken_, accessToken: accessToken_, token: accessToken_, niceClaims: claims});
        }
      };
      // call the async function
      asyncFunction();
    }, [error, getAccessTokenSilently, getIdTokenClaims, isLoading],
  );

  return {
    token: tokenState.token,
    idToken: tokenState.idToken,
    accessToken: tokenState.accessToken,
    niceClaims: tokenState.niceClaims,
    user,
    isLoading,
    error,
  };
};

