import {
  ISaasUser,
  IExternalMailForward,
  IExternalShare,
  IRiskPolicyTrigger,
} from './SaasUser';


export enum SaasIdentifier {
  atlassian = 'atlassian',
  box = 'box',
  bamboohr = 'bamboohr',
  docusign = 'docusign',
  dropbox = 'dropbox',
  // dynamics365 = 'dynamics365',
  egnyte = 'egnyte',
  employment_hero = 'employment_hero',
  github = 'github',
  gitlab = 'gitlab',
  googlews = 'googlews',
  hibob = 'hibob',
  hubspot = 'hubspot',
  // myob = 'myob',
  lucid = 'lucid',
  monday = 'monday',
  office365 = 'office365',
  microsoft365 = 'microsoft365',
  okta = 'okta',
  salesforce = 'salesforce',
  sendgrid = 'sendgrid',
  servicenow = 'servicenow',
  slack = 'slack',
  smartsheet = 'smartsheet',
  // sumologic = 'sumologic',
  xero = 'xero',
  zendesk = 'zendesk',
  zoom = 'zoom',
}

export enum SaasHealthRating {
  CRITICAL = 'CRITICAL',
  MODERATE = 'MODERATE',
  GOOD = 'GOOD',
}

export type SaasStatus =
// 'good' | 'moderate' | 'critical';
  SaasHealthRating.CRITICAL |
  SaasHealthRating.MODERATE |
  SaasHealthRating.GOOD;

export type SaaSProviderUserRole =
  | 'highlyPrivilegedUser'
  | 'privilegedUser'
  | 'activeDisabledUser'
  | 'enabledUser';

// TypeScript enums create js unlike other types - so, evil
// export enum SaasDetailInfo {
//   hpu = 'hpu', // Highly privileged user
//   pu = 'pu', // privileged user
//   eu = 'eu', // enabled user
// }

export interface ISaas {
  name: string;
  identifier: string;
  pubId?: string;
  status?: SaasStatus;
  calculatedName?: string
}

export interface ConnectedSaas extends ISaas {
  // saas?: ISaas;
  // note this extends ISaas so we have name, description, pubId
  // in addition to the above

  connectionId: string;
  calculatedName: string;
  nickname?: string;
}

export interface ISaasDetails { // extends ISaas {
  saas: ISaas,
  noOfMfa: number;
  users: ISaasUser[];
}

export interface ISaasReport extends ISaasDetails { // , ISaas {
  noOfMfa: number;
  users: ISaasUser[];
  riskPolicyEvents: IRiskPolicyTrigger[];
  externalMailForwards: IExternalMailForward[];
  externalShares: IExternalShare[];
}

// See SaasStore transform
export interface ISingleSaasViewData extends ISaasReport {
  privilegedUsers: ISaasUser[];
  highlyPrivilegedUsers: ISaasUser[];
  enabledUsers: ISaasUser[];
  externalUsers: ISaasUser[];
  inactive60DaysUsers: ISaasUser[];
}

export type PrivilegeLevelUserData = { privilege_level: string } &
  Record<
    string,
    number
  >;

export interface IGraphQLConnectedSaasResponse {
  privilege_levels?: PrivilegeLevelUserData[], // 6 Jul 23
  saas: ISaasGraphQLResponse;
  pub_id: string;
  name: string;
  description: string;

  nick_name: string;

  drifts_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

// now only used by transformSaasAccessException because
// of the nesting of saas within each client_saas
export interface IClientSaasGroupedGraphQLResponse {
  saas: ISaasGraphQLResponse
}

export interface ISaasGraphQLResponse {
  // either the saasResponse or the spread out one following it
  // how to do do that properly in TypeScript
  name: string;
  description: string;
  pub_id: string;
  privilege_levels?: PrivilegeLevelUserData[],
  drifts_aggregate?: any,
  latest_saas_users?: any,
}


export interface ISaasService {
  // getSnapshots: () => Promise<string[]>;
  // getSaases: () => Promise<ISaas[]>;
  getSaasDetails: (
    pubId: string,
    driftFrom: string
  ) => Promise<ISaasDetails | null>;
}

