import memoizee from 'memoizee';
import {SaasHealthRating, SaasStatus} from 'src/types/Saas';

export const getStatusColor = memoizee(
  (status: SaasStatus, { error, warning, success }: Record<string, string>) => {
    switch (status) {
      case SaasHealthRating.CRITICAL:
        return error;
      case SaasHealthRating.MODERATE:
        return warning;
      default:
        return success;
    }
  }
);
