import {useMutation, useQuery} from "@tanstack/react-query";
import {useAuth0Token} from "src/hooks/Auth0Hooks";

// Combine the local 'enabled' calculation with the
// any 'enabled' value passed into this hook, and
// combine them. Later, below, we combine the other
// TanStack options with the combined 'enabled' value.
// Quite a song and dance resulting from "Rules of Hooks"
const calculatedEnabled = ({
  isLoading,
  token,
  tanstackOptions,
}: {
  isLoading: boolean,
  token?: string,
  tanstackOptions: any,
}) => {
  const localEnabled = !isLoading && !!token;
  const {enabled: enabledArg, ...otherTanstackOptions} = tanstackOptions;
  const enabled = localEnabled && (
    // this was just enabledArg before 11 May 23
    (enabledArg === undefined) ? true : enabledArg
  );
  const result = {otherTanstackOptions, enabled};
  return result;
}

// Fetches the Access Token then enables the React Query.
// Used little - see src/services/SaasService/SaasView.ts
export const useQueryWithAccessToken = ({
  queryKey,
  queryFunction,
  queryFunctionVariables = {},
  tanstackOptions = {},
}: {
  queryKey: unknown[],
  queryFunction: Function,
  queryFunctionVariables: any,
  tanstackOptions?: any,
}): any => {
  const {
    token,
    isLoading,
    error,
  } = useAuth0Token();

  if (error) console.error({error})

  // calculate enabled but also allow for a provided enabled value
  const {otherTanstackOptions, enabled} = calculatedEnabled({
    isLoading, token, tanstackOptions
  });

  return useQuery({
    queryKey,
    // signal supplied by tanstack to abort
    // tanstack.com/query/v5/docs/react/guides/query-cancellation
    queryFn: async ({signal}: any) => queryFunction({
      accessToken: token,
      parameters: queryFunctionVariables,
      signal // aborts on component garbage collection, I think
    }),
    ...otherTanstackOptions, enabled
  });
};

// based on older version of TanStack.  Used by most queries.
export const useLegacyQueryWithToken = (
  queryKey: unknown[],
  queryFunction: Function,
  queryFunctionVariables: any = {},
  reactQueryOptions: any = {}
) => {
  const {
    token,
    isLoading,
    error,
  } = useAuth0Token();

  if (error) console.error({error})

  // calculate enabled but also allow for a provided enabled value
  const enabled =
    !isLoading &&
    token !== undefined &&
    (reactQueryOptions.enabled);

  return useQuery(
    queryKey,
    // signal supplied by Tanstack for aborting
    // tanstack.com/query/v5/docs/react/guides/query-cancellation
    ({signal}) =>
      queryFunction(
        token,
        queryFunctionVariables,
        signal // aborts on component garbage collection, I think
      ),
    {...reactQueryOptions, enabled}
  );
};


// this version has better 'enabled' handling than
// the legacy one below
export const useMutationWithAccessToken = ({
  // the actual mutation function, from services
  mutation,
  // variables defined when creating the mutations
  mutationCreationArgs = {},
  // built-in Tanstack options
  // we combine them with the enabled option, below
  // feature not currently used for mutations
  tanstackOptions = {},
}: {
  // the actual mutation function, from services
  mutation: any,
  // args when creating the mutations
  mutationCreationArgs?: any,
  // built-in Tanstack options
  // we combine them with the enabled option, below
  // feature not currently used for mutations
  tanstackOptions?: any,
}) => {
  const {
    token,
    isLoading,
    error,
  } = useAuth0Token();
  if (error) console.error({error})

  const {otherTanstackOptions, enabled} = calculatedEnabled({
    isLoading, token, tanstackOptions
  });

  const result = useMutation({
    mutationFn: (
      // args when calling the mutation
      mutationCallArgs: any = {}
    ) => {

      return mutation(
        // create a single object combining
        // - the token,
        // - the mutation creation args
        // - the mutation call args.
        // this object goes to the mutation in services
        // TanStack limits the number of arguments to one.
        // This means we have to mix up the auth0 token,
        // the creation args and the call args
        {
          // we give the auth0 token a unique name so
          // it doesn't clash with mutation-arg names
          auth0HasuraAccessToken: token,
          ...mutationCallArgs,
          ...mutationCreationArgs
        },
      )
    },
    ...otherTanstackOptions, enabled, // the TanStack options
  })

  return result;
};

// Executes a graphQL mutation without a token
// Use for requests to Hasura from unauthenticated users
export const usePublicMutation = ({
   mutation,
   mutationCreationArgs = {},
   tanstackOptions = {},
 }: {
  mutation: any,
  mutationCreationArgs?: any,
  tanstackOptions?: any,
}) => {
  const result = useMutation({
    mutationFn: (
      // args when calling the mutation
      mutationCallArgs: any = {}
    ) => {
      return mutation(
        {
          ...mutationCallArgs,
          ...mutationCreationArgs
        },
      )
    },
    ...tanstackOptions,
  })

  return result;
};
// older version of the above
// TODO update the half-dozen uses of this - all for Add Saas
// The mutation creation AND the service signature will need updating
export const useLegacyMutationWithIdToken = (
  mutation: any,
  mutationVariables: any = {},
  tanstackOptions: any = {}
) => {
  const {
    token,
    isLoading,
    error,
  } = useAuth0Token();

  if (error) console.error({error})

  const enabled = !isLoading && !!token;

  const result = useMutation(
    () => mutation(
      token,
      {...mutationVariables},
    ),
    {...tanstackOptions, enabled}
  )

  return token ? result : undefined;
};
